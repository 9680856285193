import API, { graphqlOperation } from '@aws-amplify/api'
import loader from './loader'
import * as queries from './../gql/queries'
import * as mutations from './../gql/mutations'
import notification from './notification'
import building from 'assets/icons/building'

// Action Constants
export const ACTION = {
  LOAD_KEYS: 'api-load-keys',
  SET_WEBHOOK: 'set-webhook-url',
  GET_WEBHOOK: 'get-webhook-url',
  GENERATE_TOKEN: 'generate-api-token',
  CLEAR_GENERATED: 'clear-generated-api-token',
  GET_LINEDATA: 'get-line-data',
  SET_LINEDATA: 'set-line-data',
}

export const LOAD = ACTION

// Initial state
const initial = {
  data: [],
  generated: null,
  webhook: null,
  linedata: [],
  name: '',
}


const createApiAccessKeyMutation = `
  mutation {
    createApiAccessKey {
      prefix
      generatedToken
    }
  }
`

export const getApiAccessKeys = () => {
  return loader.load(LOAD.LOAD_KEYS, (dispatch) => {
    return API.graphql(graphqlOperation(queries.getApiAccessKeys))
      .then((result) => dispatch({ type: ACTION.LOAD_KEYS, data: result.data.getApiAccessKeys }))
  })
}

export const clearGenerated = () => ({ type: ACTION.CLEAR_GENERATED })

export const clearLineData = () => ({
  type: 'CLEAR_LINE_DATA'
});

export const resetLineState = () => ({
  type: 'RESET_LINE_STATE'
});

export const createApiAccessKey = () => {
  return loader.load(LOAD.GENERATE_TOKEN, (dispatch) => {
    return API.graphql(graphqlOperation(createApiAccessKeyMutation))
      .then((result) => dispatch({ type: ACTION.GENERATE_TOKEN, data: result.data.createApiAccessKey }))
      // Refresh access keys
      .then(() => dispatch(getApiAccessKeys()))
  })
}

export const setWebhook = (url, enabled) => {
  return loader.load(LOAD.SET_WEBHOOK, (dispatch) => {
    return API.graphql(graphqlOperation(mutations.setWebhook, { url, enabled }))
      .then((result) => dispatch({ type: ACTION.SET_WEBHOOK, data: result.data.setWebhook }))
      .then(() => dispatch(notification.success('Webhook successfully updated')))
  })
}

export const getWebhook = () => {
  return loader.load(LOAD.GET_WEBHOOK, dispatch => {
    return API.graphql(graphqlOperation(queries.getWebhook))
      .then(result => dispatch({ type: ACTION.GET_WEBHOOK, data: result.data.getWebhook }))
  })
}

export const setLineData = (position, color, isVisible, name, buildingId, floorId, areaId) => {
  return loader.load(LOAD.SET_LINEDATA, (dispatch) => {
    // Trigger GraphQL mutation
    return API.graphql(graphqlOperation(mutations.setLineData, { position, color, isVisible, name, buildingId, floorId, areaId }))
      .then((result) => {
        // Ensure the result is wrapped in an array
        const dataAsArray = Array.isArray(result.data.setLineData) ? result.data.setLineData : [result.data.setLineData];
        
        // Dispatch the array to update the Redux store
        dispatch({ type: ACTION.SET_LINEDATA, data: dataAsArray });
      
      })
      .catch((error) => {
        // Handle network errors or issues during the mutation
        dispatch(notification.error('Line creation failed, please select all filters'));
      });
  });
}


// Get lineData action
export const getLineData = (areaId) => {
  return loader.load(LOAD.GET_LINEDATA, (dispatch) => {
    return API.graphql(graphqlOperation(queries.getLineData, { areaId }))
      .then((result) => dispatch({ type: ACTION.GET_LINEDATA, data: result.data.getLineData }))
  })
}

// Store line name in redux
export const setLineNameInRedux = (name) => ({
  type: 'SET_LINE_NAME',
  payload: name
});

//set line name from modal in redux
const lineDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LINE_NAME':
      return {
        ...state,
        lineName: action.payload,  // Store only the lineName
      };
    default:
      return state;
  }
};

// Reducer to manage state
export const reducer = (state = initial, action) => {
  switch (action.type) {
    case ACTION.CLEAR_GENERATED:
      return { ...state, generated: null }
    case ACTION.LOAD_KEYS:
      return { ...state, data: action.data }
    case ACTION.GET_WEBHOOK:
      return { ...state, webhook: action.data }
    case ACTION.SET_WEBHOOK:
      return { ...state, webhook: action.data }
    case ACTION.GENERATE_TOKEN:
      return { ...state, generated: action.data }
    case ACTION.GET_LINEDATA:
      return { ...state, linedata: action.data }
    case ACTION.SET_LINEDATA:
      return { ...state, linedata: action.data }
    case 'SET_LINE_NAME':
      return { ...state, name: action.payload }
    case 'CLEAR_LINE_DATA':
      return {
        ...state,
        linedata: []
      };
    default:
      return state
  }
}
